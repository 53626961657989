@font-face {
font-family: '__Gilroy_3fe82b';
src: url(/_next/static/media/a112078eb38aa119-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__Gilroy_3fe82b';
src: url(/_next/static/media/73fdfd3d28dae0f0-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
}@font-face {font-family: '__Gilroy_Fallback_3fe82b';src: local("Arial");ascent-override: 72.87%;descent-override: 21.40%;line-gap-override: 22.44%;size-adjust: 106.07%
}.__className_3fe82b {font-family: '__Gilroy_3fe82b', '__Gilroy_Fallback_3fe82b'
}.__variable_3fe82b {--font-gilroy: '__Gilroy_3fe82b', '__Gilroy_Fallback_3fe82b'
}

